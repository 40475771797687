import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../views/${view}.vue`);
}

const routes = [
    {
        path: "/",
        redirect: "/dashboard"
    },

    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {layout: 'main', auth: true},
        component: loadView('Dashboard')
    },
    {
        path: '/notifications',
        name: 'notifications',
        meta: {layout: 'main', auth: {roles: ['manager', 'guard', 'driver','receiver']}},
        component: loadView('Notifications')
    },

    {
        path: '/entrance',
        name: 'entrance',
        meta: {layout: 'main', auth: {roles: ['manager','receiver']}},
        component: loadView('Entrances')
    },

    {
        path: '/entrance_auto/:id',
        name: 'entrance_auto',
        meta: {layout: 'main', auth: {roles: ['manager','receiver']}},
        component: loadView('EntranceAutos')
    },
    {
        path: '/entrance_auto/:type_receipt/create',
        name: 'entrance_auto.create',
        meta: {layout: 'main', auth: { roles: ['manager','receiver'] }},
        component: loadView('EntranceAutoForm')
    },
    {
        path: '/entrance_auto/:type_receipt/:id/edit',
        name: 'entrance_auto.edit',
        meta: {layout: 'main', auth: { roles: ['manager', 'receiver'] }},
        component: loadView('EntranceAutoForm')
    },

    {
        path: '/entrance_railway/:id',
        name: 'entrance_railway',
        meta: {layout: 'main', auth: {roles: ['manager', 'receiver']}},
        component: loadView('EntranceRailways')
    },
    {
        path: '/entrance_railway/:type_receipt/create',
        name: 'entrance_railway.create',
        meta: {layout: 'main', auth: { roles: ['manager', 'receiver'] }},
        component: loadView('EntranceRailwayForm')
    },
    {
        path: '/entrance_railway/:type_receipt/:id/edit',
        name: 'entrance_railway.edit',
        meta: {layout: 'main', auth: { roles: ['manager', 'receiver'] }},
        component: loadView('EntranceRailwayForm')
    },

    {
        path: '/departure',
        name: 'departure',
        meta: {layout: 'main', auth: {roles: ['manager', 'receiver']}},
        component: loadView('Departures')
    },
    {
        path: '/departure_auto/:id',
        name: 'departure_auto',
        meta: {layout: 'main', auth: {roles: ['manager', 'receiver']}},
        component: loadView('DepartureAutos')
    },
    {
        path: '/departure_auto/:type_receipt/create',
        name: 'departure_auto.create',
        meta: {layout: 'main', auth: { roles: ['manager', 'receiver'] }},
        component: loadView('DepartureAutoForm')
    },
    {
        path: '/departure_auto/:type_receipt/:id/edit',
        name: 'departure_auto.edit',
        meta: {layout: 'main', auth: { roles: ['manager', 'receiver'] }},
        component: loadView('DepartureAutoForm')
    },

    {
        path: '/departure_railway/:id',
        name: 'departure_railway',
        meta: {layout: 'main', auth: {roles: ['manager', 'receiver']}},
        component: loadView('DepartureRailways')
    },
    {
        path: '/departure_railway/:type_receipt/create',
        name: 'departure_railway.create',
        meta: {layout: 'main', auth: { roles: ['manager', 'receiver'] }},
        component: loadView('DepartureRailwayForm')
    },
    {
        path: '/departure_railway/:type_receipt/:id/edit',
        name: 'departure_railway.edit',
        meta: {layout: 'main', auth: { roles: ['manager', 'receiver'] }},
        component: loadView('DepartureRailwayForm')
    },
    {
        path: '/container/:id/add',
        name: 'container_add',
        meta: {layout: 'main', auth: true},
        component: loadView('ContainerOk')
    },
    {
        path: '/container/:id/error',
        name: 'container_error',
        meta: {layout: 'main', auth: true},
        component: loadView('ContainerError')
    },

    {
        path: '/security',
        name: 'security',
        meta: {layout: 'main', auth: {roles: ['manager', 'guard']}},
        component: loadView('Security')
    },
    {
        path: '/security/entry',
        name: 'security.entry',
        meta: {layout: 'main', auth: {roles: ['manager', 'guard']}},
        component: loadView('SecurityEntry')
    },
    {
        path: '/security/exit',
        name: 'security.exit',
        meta: {layout: 'main', auth: {roles: ['manager', 'guard']}},
        component: loadView('SecurityExit')
    },
    {
        path: '/moving',
        name: 'moving',
        meta: {layout: 'main', auth: {roles: ['manager', 'driver']}},
        component: loadView('Moving')
    },

    // {
    //     path: '/security',
    //     name: 'security',
    //     meta: {layout: 'main', auth: {roles: ['driver']}},
    //     component: loadView('SecurityForm')
    // },



    {
        path: '/profile',
        name: 'profile',
        meta: {layout: 'main', auth: true},
        component: loadView('PartnerForm')
    },

    {
        path: '/auth/login',
        name: 'login',
        meta: { layout: 'single-page', auth: false },
        component: loadView('Auth/Login')
    },

    {
        path: '/auth/register',
        name: 'register',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Login')
    },
    {
        path: '/auth/forgot',
        name: 'forgot',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Forgot')
    },
    {
        path: '/auth/forgot/check',
        name: 'reset_password',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/ResetPassword')
    },
    {
        path: '/404',
        name: 'error-404',
        meta: {layout: 'main', auth: true},
        component: loadView('Errors/NotFound')
    },
    {
        path: '/403',
        name: 'error-403',
        meta: {layout: 'main', auth: true},
        component: loadView('Errors/Forbidden')
    },
    {
        path: '/block',
        name: 'block',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Blocked')
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Maintenance')
    },
    {
        path: '*',
        redirect: '/404',
        meta: {layout: 'default', auth: undefined},
    },
];

Vue.router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    hashbang: false,
    linkActiveClass: 'active',
    transitionOnLoad: true,
    mode: 'history',
    base: __dirname,
    routes: routes
});

export default Vue.router;
