<template>
    <v-bottom-navigation v-model="value" inset absolute app
                         dark
                         shift
                         grow
                         :value="value"
                         background-color="primary"
    >
        <v-btn v-for="(item, index) in navigationItems" :key="index"
               v-show="item.visible"
               :value="item.to.name"
               @click="menuClick(item.to.name)"
               >
            <span>{{ $t(item.title) }}</span>
            <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
export default {
    name: "app-sidebar-footer",
    data() {
        return {
            value: this.$route.name ,
            navigationItems:
                [
                    {
                        title: 'menu_entrance',
                        icon: 'mdi-truck-plus-outline mdi-36px',
                        to: {name: "entrance"},
                        action: this.forceRerender,
                        visible: this.can(['manager', 'receiver']),
                        exact: false
                    },
                    {
                        title: 'menu_security',
                        icon: 'mdi-security mdi-36px',
                        to: {name: "security"},
                        action: this.forceRerender,
                        visible: this.can(['manager', 'guard']),
                        exact: false
                    },
                    {
                        title: 'menu_moving',
                        icon: 'mdi-crane mdi-36px',
                        to: {name: "moving"},
                        action: this.forceRerender,
                        visible: this.can(['manager', 'driver']),
                        exact: false
                    },

                    {
                        title: 'menu_departure',
                        icon: 'mdi-truck-minus-outline mdi-36px',
                        to: {name: "departure"},
                        action: this.forceRerender,
                        visible: this.can(['manager', 'receiver']),
                        exact: false
                    },

                ],
        };
    },
    methods: {
        menuClick(name){
            this.$router.push({
                name: name,
            })
        }
    }
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default{
    height: inherit !important;
}
.theme--dark.v-btn.v-btn--has-bg {
    background-color: transparent !important;
}
</style>


