import "roboto-fontface/css/roboto/roboto-fontface.css"
import "cropperjs/dist/cropper.css"
import "element-ui/lib/theme-chalk/index.css";
import '@mdi/font/css/materialdesignicons.css'
import '../styles/custom.css'

import Vue from "vue";
import Vuetify from "vuetify/lib";

import en from 'vuetify/lib/locale/en'
import ru from 'vuetify/lib/locale/ru'
import kk from '../locales/vendor/vuetify/kk'

Vue.use(Vuetify);

export default new Vuetify({
    options: {
        customProperties: true,
    },
    /*
    breakpoint: {
        scrollBarWidth: 16,
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 0,
            sm: 470,
            md: 600,
            lg: 800,
            xl: 1280,
            xxl: 1920
        },
    },
    */
    theme: {
        themes: {
            light: {
                primary: '#05599D',
                top_menu: '#ffffff',
                theme_color: "#05599D",
                background_color: "#05599D",
                themeColor: "#05599D",
                appleMobileWebAppStatusBarStyle: "#05599D",


                /*
                primary: '#1976D2',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                */
            },
            dark: {
                primary: '#424242',
                top_menu: '#ffffff',
                theme_color: "#424242",
                background_color: "#424242",
                themeColor: "#424242",
                appleMobileWebAppStatusBarStyle: "#424242",
            },
        },
    },
    lang: {
        locales: {en, ru, kk},
        current: process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE
    },
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4
    }
});
