import { initializeApp } from "firebase/app"
import {getMessaging, onMessage} from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyAId2dNcpGlWS1R0DB7adDHH6rrkWSj8j0",
    authDomain: "atasuexpress-fa454.firebaseapp.com",
    projectId: "atasuexpress-fa454",
    storageBucket: "atasuexpress-fa454.appspot.com",
    messagingSenderId: "560286467680",
    appId: "1:560286467680:web:ec54f0ec1ccefbcf0ba505",
    measurementId: "G-V5DKEW3YRC"

};

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

onMessage(messaging, (payload) => {
    //console.log('Message received.3 ', payload);
    const data = {...payload.notification, ...payload.data};
    const notificationTitle = data.title;
    let notificationOptions = {
        body: data.body,
        requireInteraction: true,
        data
    }
    if (data.icon) {
        notificationOptions.icon = data.icon
    }

    if (data.image) {
        notificationOptions.image = data.image
    }
    if (data.click_action) {
        notificationOptions.click_action = data.click_action
    }
    const message = new Notification(notificationTitle, notificationOptions);
    message.addEventListener('click', function() {
        window.location.href = data.link;
    });
});
